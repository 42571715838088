<template>
  <div class="ModermPO">
    <ContactInfo></ContactInfo>
    <img
      class="animate__animated"
      v-scroll="'animate__fadeIn'"
      src="@assets/images/ExamCustomized1.png"
      alt=""
      width="100%"
    />
    <div class="number1">
      <img src="@assets/images/SystemBuild2.png" alt="" width="100%" />
      <div class="contain">
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p style="font-weight: normal !important">服务介绍</p>
        </div>
        <p
          class="Rem0_24 animate__animated"
          v-scroll="'animate__fadeIn'"
          style="
            animation-delay: 1s;
            font-weight: normal !important;
            text-indent: 2em;
          "
        >
          所有的业绩都由过程产生，而过程又是各部门作业的纽带，故面向过程业绩的考核才能很好地解决部门间的协作问题，也就是考核“过程业绩”比考核“部门业绩”要科学得多。基于这样的原理，建立“过程目标”和“过程要求”（工作标准）将构成企业的“绩效考核标准”。目前，还没有其它方法比在“过程”中建立绩效考核标准更科学的。
        </p>
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p style="font-weight: normal !important">服务内容:</p>
        </div>
        <div class="ul">
          <span
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list1"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInRightBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list2"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_25 animate__animated"
            v-scroll="'animate__fadeInRightBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list3"
            :key="index"
            :class="{ dispalyNone: !item }"
          >
            {{ item }}</span
          >
        </div>
      </div>
    </div>
    <div class="number3">
      <img src="../../../assets/images/NumToGround4.png" alt="" />
      <div class="contain">
        <div class="Rem0_4">
          <p>考核标准的特点</p>
          <p></p>
        </div>
        <div class="fivebox">
          <div
            class="animate__animated"
            v-scroll="'animate__rollIn'"
            v-for="(item, index) in featureList"
            :key="index"
          >
            <img :src="item.img" />
            <p class="p1 Rem0_24">
              {{ item.p1 }}
            </p>
            <p class="p2 Rem0_20">
              {{ item.p2 }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="number4">
      <img
        class="bagimg"
        v-show="tabIndex == 0"
        src="../../../assets/images/ExamCustomized2.png"
        alt=""
      />
      <img
        class="bagimg"
        v-show="tabIndex == 1"
        src="../../../assets/images/ExamCustomized3.png"
        alt=""
      />
      <div v-scroll="'animate__bounceIn'" class="Rem0_4 animate__animated">
        <p>业绩考核标准体系</p>
        <p></p>
      </div>
      <div class="tabBox" id="slideshow">
        <div class="tab">
          <div
            class="tab-item"
            v-for="(item, index) in tabList"
            :key="index"
            @click="handleTab(index)"
          >
            <div class="tabBtn Rem0_30" :class="{ active: index === tabIndex }">
              {{ item }}
            </div>
            <img
              v-show="index == tabIndex"
              src="../../../assets/images/ProcessOptimize21.png"
              alt
            />
          </div>
        </div>
        <div class="index1" v-show="tabIndex == 0">
          <img src="../../../assets/images/ExamCustomized4.png" alt="" />
        </div>
        <div class="index2" v-show="tabIndex == 1">
          <div class="one">
            <div>
              <div class="Rem0_30">
                <p>2023-2024年战略</p>
                <p>进入行业前五名</p>
              </div>
              <p class="Rem0_24">
                1.产品市场占有率上升12% <br />2.打造全信息化组织<br />
                3.获得质量奖<br />
                4.打造具有竞争力的供应链
                <br />5.产品不良低于300PPM<br />6.降低生产成本
              </p>
            </div>
            <div>
              <div class="Rem0_30">
                <p>2025-2026年战略</p>
                <p>获“中国名牌”产品</p>
              </div>
              <p class="Rem0_24">
                1.新产品进入市场周期低于行业25%
                <br />2.建设智能化二期厂房并投入使用<br />
                3.出口产品占比超过40%<br />
                4.打响具有竞争力的产品知<br />
                5.降低制造成本8%<br />
                6.产品不良低于100PPM
              </p>
            </div>
            <div>
              <div class="Rem0_30">
                <p>2025-2026年战略</p>
                <p>国际有影响的组织</p>
              </div>
              <p class="Rem0_24">
                1.成为国内行内标杆组织<br />
                2.产品主要指标领先同行<br />
                3.建立优势产品的行业标准<br />
                4.产品不良低于50PPM <br />5.市场占有率数一
              </p>
            </div>
          </div>
          <div class="tow">
            <div class="Rem0_30">
              <img src="../../../assets/images/ExamCustomized10.png" alt="" />
              <p>1<br />STEP</p>
            </div>
            <img
              class="lineimg"
              src="../../../assets/images/ExamCustomized11.png"
              alt=""
            />
            <div class="Rem0_30">
              <img src="../../../assets/images/ExamCustomized9.png" alt="" />
              <p>2<br />STEP</p>
            </div>
            <img
              class="lineimg"
              src="../../../assets/images/ExamCustomized11.png"
              alt=""
            />
            <div class="Rem0_30">
              <img src="../../../assets/images/ExamCustomized8.png" alt="" />
              <p>3<br />STEP</p>
            </div>
          </div>
        </div>
        <div class="index3" v-show="tabIndex == 2">
          <img src="../../../assets/images/ExamCustomized5.png" alt="" />
        </div>
        <div class="index4" v-show="tabIndex == 3">
          <p class="Rem0_24">
            战略任务之间是有逻辑关系的，采用矩阵的方法就能清楚地发现它们先后和制约关系
          </p>
          <img
            class="img4"
            src="../../../assets/images/ExamCustomized6.png"
            alt=""
          />
        </div>
        <div class="index5" v-show="tabIndex == 4">
          <img
            class="img4"
            src="../../../assets/images/ExamCustomized7.png"
            alt=""
          />
          <div class="Rem0_25">
            <p>
              <img
                class="img4"
                src="../../../assets/images/ExamCustomized12.png"
                alt=""
              />实时呈现公司绩效指标完成进度
            </p>
            <p>
              <img
                class="img4"
                src="../../../assets/images/ExamCustomized13.png"
                alt=""
              />实时呈现各级管理者和员工绩效进度
            </p>
            <p>
              <img
                class="img4"
                src="../../../assets/images/ExamCustomized14.png"
                alt=""
              />管理者对下属绩效完成进度一目了然，可及时进行跟踪、督导和帮助
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactInfo from "../../../components/public/ContactInfo.vue";
export default {
  components: { ContactInfo },
  data() {
    return {
      serveList: {
        list1: [
          "建立战略阶梯图",
          "战略分解",
          "明确过程目标（KPI）",
          "构建经营路线图",
          "建立目标关联图",
          "明确派生性目标",
        ],
        list2: [
          "明确制约性目标",
          "目标分解",
          "确定目标权重",
          "指标系统的四个平衡",
          "综合发展指数计算",
          "编制经营计划",
        ],
        list3: [
          "例行事和例外事",
          "重点工作清单",
          "明确常规工作要求",
          "明确流程输出要求",
          "建立岗位考核标准",
          "",
        ],
      },
      featureList: [
        {
          img: require("@assets/images/a.png"),
          p1: "高量化的考核标准",
          p2: "业绩考核标准量化率达到70%，是行业内最高的。这是一流企业的重要指标，更是业绩考核科学性的重要指标。",
        },
        {
          img: require("@assets/images/b.png"),
          p1: "能力点的综合",
          p2: "考核的关键是将个人的能力还原到很多的能力点上，业绩考核实质是n个能力点的综合考核，这是业绩标准制定和考核的创新性思维。",
        },
        {
          img: require("@assets/images/c.png"),
          p1: "考核标准的结构性",
          p2: "从战略到目标指标，再到作业要求（标准）一直延伸下来，我们探索出了一套成熟的方法使它们真正做到综合平衡，且具有很强的逻辑性和结构性。",
        },
        {
          img: require("@assets/images/d.png"),
          p1: "简单简洁",
          p2: "4.考核的目标指标、工作标准非常精简，比阿米巴、平衡积分卡等哪些复杂的考核方法简单得多，受到了顾客的普遍认可。 ",
        },
      ],
      tabList: [
        "例外事和例行事",
        "战略阶梯",
        "年度战略任务清单",
        "战略任务矩阵",
        "考核标准数字化",
      ],
      tabIndex: 0,
      tabImg: [
        require("../../../assets/images/ProcessOptimize22.png"),
        require("../../../assets/images/ProcessOptimize23.png"),
        require("../../../assets/images/ProcessOptimize24.png"),
        require("../../../assets/images/ProcessOptimize25.png"),
        require("../../../assets/images/ProcessOptimize26.png"),
      ],
      slideshow: null,
    };
  },
  mounted() {
    this.setTimeoutImg();
    this.slideshow = document.getElementById("slideshow");
    this.slideshow.addEventListener("mouseover", this.stopSlideshow);
    this.slideshow.addEventListener("mouseout", this.startSlideshow);
  },
  methods: {
    handleTab(index) {
      this.tabIndex = index;
    },
    setTimeoutImg() {
      var _this = this;
      this.setIntervalDiv = setInterval(() => {
        _this.tabIndex = _this.tabIndex + 1;
        console.log(_this.tabIndex);
        if (_this.tabIndex == 5) {
          _this.tabIndex = 0;
        }
      }, 1800);
    },
    stopSlideshow() {
      clearInterval(this.setIntervalDiv);
    },
    startSlideshow() {
      this.setTimeoutImg();
    },
  },
  destroyed() {
    clearTimeout(this.setIntervalDiv);
  },
};
</script>

<style lang="less" scoped>
.ModermPO {
  .number1 {
    position: relative;
    .contain {
      width: 80%;
      margin: 0 10%;
      position: absolute;
      top: 0;
      color: white;
      text-align: start;
      .Rem0_4:nth-of-type(1) {
        display: flex;
        justify-content: center;
        margin-top: 5%;
      }
      .Rem0_4:nth-of-type(2) {
        margin: 4% 0 0.5% 0;
      }
      p:nth-of-type(1) {
        padding-top: 30px;
      }
      .ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        opacity: 0.83;
        span {
          flex: 1;
          border: 1px solid;
          text-align: center;
          border: 1px solid #909090;
          border-radius: 5px;
          margin: 0 6px;
          margin-top: 20px;
          background: linear-gradient(to right, #555974, #5d616f);
          padding: 0.18rem 0;
          cursor: default;
        }
        .dispalyNone {
          background: transparent;
          border: transparent;
        }
      }
    }
  }
  .number3 {
    position: relative;
    > img {
      width: 100%;
    }
    .contain {
      position: absolute;
      width: 64%;
      margin: 0 18%;
      text-align: center;
      top: 0;
      .Rem0_4 {
        margin-top: 6%;
        color: #4375c8;
        p:nth-of-type(2) {
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .fivebox {
      display: flex;
      justify-content: space-between;
      margin-top: 11%;
      div:hover {
        background: #4375c8;
        .p1 {
          color: white !important;
          transform: scale(1.25);
        }
        .p2 {
          color: white !important;
        }
      }
      div {
        flex: 1;
        text-align: center;
        padding: 35px 20px 15px 20px;
        border-radius: 15px;
        margin: 1%;
        background: #f6f6f6;
        img {
          width: 0.65rem;
          height: 0.65rem;
        }
        .p1 {
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 20px;
          color: #4375c8;
        }
        .p2 {
          line-height: 0.35rem !important;
          text-align: start;
          color: #333333;
        }
      }
    }
  }
  .number4 {
    position: relative;
    min-height: 9.54rem;
    padding-bottom: 3%;
    .bagimg {
      position: absolute;
      width: 100%;
      height: 11.5rem;
    }
    .Rem0_4 {
      text-align: center;
      padding-top: 3%;
      color: #4375c8;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .tabBox {
      width: 80%;
      margin: 0 auto;
      margin-top: 0.6rem;
      text-align: center;
      .tab {
        display: flex;
        justify-content: space-around;
        margin-bottom: 1%;
        .tab-item {
          position: relative;
          .tabBtn {
            min-width: 2.68rem;
            background: #ffffff;
            border-radius: 0.4rem;
            border: 1px solid #e5e6e7;
            height: 0.78rem;
            line-height: 0.78rem;
            font-weight: 400;
            color: #000000;
            text-align: center;
            box-sizing: border-box;
            padding: 0 0.4rem;
            cursor: pointer;
          }
          img {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -0.15rem;
            width: 2.14rem;
            height: 0.08rem;
          }
          .active {
            font-weight: bold;
            color: #ffffff;
            background: #4375c8;
          }
        }
      }
      .index1 {
        position: relative;
        img {
          margin-top: 0.2rem;
          width: 55%;
          border: 1px solid silver;
          padding: 0.5rem;
        }
      }
      .index2 {
        position: relative;
        width: 82.5%;
        margin: 0 10%;
        > div {
          display: flex;
          align-items: end;
          justify-content: center;
        }
        .one > div {
          flex: 1;
          background: white;
          margin: 0.25rem 0.12rem;
          border-radius: 10px;
          border: 1px solid #e0dede;
          overflow: hidden;
          .Rem0_30 {
            color: white;
            padding: 0.25rem 0;
            text-align: center;
            font-weight: bold;
          }
          .Rem0_24 {
            text-align: start;
            color: #000000;
            line-height: 0.5rem !important;
            padding: 0.16rem 0.2rem;
          }
        }
        .one > div:nth-of-type(1) {
          height: 4.7rem;
          .Rem0_30 {
            background: linear-gradient(to right, #39b9bf, #1ca5ac);
          }
        }
        .one > div:nth-of-type(2) {
          height: 5.4rem;
          .Rem0_30 {
            background: linear-gradient(to right, #85c975, #6aa55c);
          }
        }
        .one > div:nth-of-type(3) {
          height: 6rem;
          .Rem0_30 {
            background: linear-gradient(to right, #3b97d4, #2278b1);
          }
        }
        .tow {
          align-items: center;
          div {
            position: relative;
            width: 1.25rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            font-weight: bold;
            margin: 0 0.3rem;
            p {
              position: absolute;
              left: 0.25rem;
              bottom: 0.25rem;
            }
          }
          .lineimg {
            width: 2.4rem;
            margin-top: 0.3rem;
          }
        }
        .tow > div:nth-of-type(1) {
          color: #1aa3aa;
        }
        .tow > div:nth-of-type(2) {
          color: #69a35b;
        }
        .tow > div:nth-of-type(3) {
          color: #1f74ad;
        }
      }
      .index3 {
        img {
          width: 62%;
          margin-top: 0.2rem;
        }
      }
      .index4 {
        text-align: center;
        padding-top: 0.2rem;
        .img4 {
          width: 50%;
          margin-top: 1.2%;
        }
      }
      .index5 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > img {
          width: 62%;
          margin-right: 0.1rem;
        }
        .Rem0_25 {
          background: linear-gradient(to right, #2f5597, #41639c);
          padding: 0.35rem 0.35rem 0.4rem 0.35rem;
          text-align: start;
          margin-top: 0.3rem;
          border-radius: 5px;
          box-shadow: 0.3rem 0.3rem 0 0.01rem #dee6f3;
          img {
            width: 0.38rem;
            margin-right: 0.2rem;
          }
          p {
            line-height: 0.4rem !important;
            display: flex;
            align-items: center;
            color: white;
            margin: 0.5rem 0;
          }
          p:nth-of-type(2) {
            margin-top: -0.2rem;
          }
        }
      }
    }
  }
}
</style>